<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <parte-trabajo-tecnico-form
          :idparte-trabajo="routeParams.idparte_trabajo"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ParteTrabajoTecnicoForm from './components/ParteTrabajoTecnicoForm'

export default {
  components: {
    ParteTrabajoTecnicoForm,
  },
  mixins: [formPageMixin],
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir técnico al parte'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      await this.$online.parteTrabajoTecnico.insertParteTrabajoTecnico(this, this.routeParams.idparte_trabajo, this.formData.idtecnico)
      this.$alert.showSnackbarSuccess(`Se ha añadido el técnico al parte de trabajo`)
      // Marco dirty el listado de partes, para que se habilite o no el botón de descargar
      await this.$dirty.modified(this.$dirty.ENTITIES.remote.parteTrabajo, this.routeParams.idparte_trabajo)
      this.$appRouter.go(-1)
    },
  },
}
</script>
